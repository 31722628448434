import React from "react";

const Copyright = () => {
  return (
    <div className="copyright">
      <div className="container">
        <div className="col-slim">
          <p>&copy; 2020 | All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Copyright;
