import React from "react";
import { Link } from "react-router-dom";

import Navbar from "./navbar";

import config from "../config.json";
import Logo from "../assets/logo.png";

function Home() {
  return (
    <div className="home">
      <img className="logo" src={Logo} alt="Art and Design" />

      <h1 className="hero-salutaion">Hello There!!!</h1>
      <p className="subtitle">
        I am{" "}
        <b className="name">
          <a href={config.linkedIn}>Mahbub Alam</a>
        </b>
      </p>
      <p>I am a professional full stack software enginner</p>
      <p>
        I love <span className="tech">PHP</span>
        <span className="tech">Javascript</span>{" "}
        <span className="tech">Laravel</span>
        <span className="tech">Node.js</span>
        <span className="tech">Wordpress</span>
      </p>
      <p>
        I ocassionally write in{" "}
        <a target="_blank" rel="noopener noreferrer" href={config.medium}>
          medium
        </a>
        , answer questions in{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={config.stackoverflow}
        >
          stackoverflow
        </a>
        , push to{" "}
        <a target="_blank" rel="noopener noreferrer" href={config.github}>
          github
        </a>
      </p>
      <p>
        I have worked with <Link to="/works">these companies so far</Link>
      </p>
      <p>
        I am going to put up some of my works <Link to="/projects">here</Link>
      </p>

      <div className="hr"></div>

      <Navbar />
    </div>
  );
}

export default Home;
