import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

import "./assets/App.css";

import Home from "./components/home";
import Works from "./components/works";
import Projects from "./components/projects";
import * as serviceWorker from "./serviceWorker";

const history = createBrowserHistory();

ReactGA.initialize("UA-175632756-1");

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

ReactDOM.render(
  <Router>
    <div>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/works">
        <Works />
      </Route>
      <Route path="/projects/:slug">
        <Projects />
      </Route>
      <Route path="/projects">
        <Projects />
      </Route>
    </div>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
