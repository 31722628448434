import React from "react";

import Navbar from "./navbar";
import WorkItem from "./workItem";

import works from "../works.json";

const Works = () => {
  return (
    <div className="page page-projects">
      <div className="container">
        <div className="col">
          <Navbar />

          <h1 className="page-title">My Works</h1>

          <div className="work-list">
            {works.map(work => (
              <WorkItem key={work.id} data={work} />
            ))}
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="col-slim">
            <p>&copy; 2020 | All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
