import React from "react";
import Parser from "html-react-parser";

const WorkItem = ({ data }) => {
  const { techs } = data;
  const techNodes = techs
    .filter((item, pos) => techs.indexOf(item) === pos)
    .map(item => (
      <span className="tech" key={item}>
        {item}
      </span>
    ));

  return (
    <div key={data.id} className="work-item">
      <h3 className="work-item-title">
        {Parser(data.title)}
        {data.remote && <span className="work-remote">Remote</span>}
      </h3>
      <div className="work-summary">{Parser(data.summary)}</div>
      {data.description && (
        <div className="work-description">{Parser(data.description)}</div>
      )}
      <div className="tech-stack">{techNodes}</div>
    </div>
  );
};

export default WorkItem;
