import React from "react";

import Navbar from "./navbar";
import Copyright from "./commons/copyright";

const Projects = () => {
  return (
    <div className="page page-works">
      <div className="container">
        <div className="col">
          <Navbar />
          <h1 className="page-title">My Projects</h1>
          <p>
            Sorry. I am still curating my projects. Please check back later.
          </p>
        </div>
      </div>
      <Copyright />
    </div>
  );
};

export default Projects;
